import { Navigate, useRoutes } from 'react-router-dom';
// context
import { BusinessTagsProvider } from '../contexts/BusinessTagsContext';
import { VendorProvider } from '../contexts/VendorContext';
import { EventTagsProvider } from '../contexts/EventTagsContext';
import { CampaignPackageProvider } from '../contexts/CampaignPackageContext';
import { CampaignCreditsProvider } from '../contexts/CampaignCreditsContext';
import { CampaignTemplateProvider } from '../contexts/CampaignTemplateContext';
import { EventsTemplateProvider } from '../contexts/EventsDetailsContext';
import { DiscountTemplateProvider } from '../contexts/discountContext';
import { VenueTemplateProvider } from '../contexts/VenuesTemplateProvider';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  Page404,
  LoginPage,
  Dashboard,
  VendorsList,
  CreateEditVendor,
  UpdateVendor,
  BusinessTagsList,
  CreateEditBusinessTags,
  EventTagsList,
  CreateEditEventTags,
  CancelTickets,
  AddCancelTickets,
  TicketOrders,
  SponsoredEvents,
  SponsoredEventsCreate,
  // CreateEvents,
  Customer,
  CreditsPackageList,
  CreateEditCreditPackage,
  AddCreditsList,
  AddTemplateList,
  AddCampaignCredits,
  AddCampaignTemplate,
  Maintenance,
  Platform,
  Platformhistroy,
  SetPlatformfee,
  UpdatePlatformfee,
  Invoices,
  CreateInvoice,
  CustomerInvoice,
  CreateGroupEvents,
  GroupEvents,
  Events,
  DiscountDashboard,
  // Maintenance,
  // PackagesList,
  // CreateEditpackages,
  EventRecords,
  CreateDiscount,
  DiscountDetails,
  UpdateDiscount,
  VenuesDashboard,
  CreateVenue,
  VenuePerDetails,
  UpdateVenue,
  EventSlotPage,
  CreateEventSlotPage,
  ArtistDetails,
  ArtistDashboard,
  EventLabelDashboard,
  CreateEventLabel,
  EventCreateStrikeOutPrice,
  UpdateEventLabel,
  EventStrikeDashboard,
  EventUpdateStrikeOutPrice,
  CreateEvents

} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: '/dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          children: [
            {
              path: 'dashboard',
              children: [{ element: <Dashboard />, index: true }],
            },
          ],
        },
        {
          element: <BusinessTagsProvider />,
          children: [
            {
              path: 'vendors',
              element: <VendorProvider />,
              children: [
                {
                  children: [
                    { element: <VendorsList />, index: true },
                    { path: 'create', element: <CreateEditVendor /> },
                    { path: 'update/:id', element: <UpdateVendor /> },
                    {
                      path: 'histroy',
                      element: <Platformhistroy />,
                    },
                  ],
                },
              ],
            },
            {
              path: 'platformfee', // This is the parent path for Platform and SetPlatformfee
              children: [
                { element: <Platform />, index: true },
                {
                  path: 'create', // This path should render the SetPlatformfee component
                  element: <SetPlatformfee />,
                },

                {
                  path: 'update',
                  element: <UpdatePlatformfee />,
                },
              ],
            },
            {
              path: 'invoices',
              children: [
                { element: <Invoices />, index: true },
                { path: 'create', element: <CreateInvoice /> },
                { path: 'customerinvoice', element: <CustomerInvoice /> },
              ],
            },

            {
              path: 'business-tags',
              children: [
                { element: <BusinessTagsList />, index: true },
                { path: 'create', element: <CreateEditBusinessTags /> },
                { path: 'edit/:name', element: <CreateEditBusinessTags edit /> },
              ],
            },
          ],
        },

        // {
        //   path: 'packages',
        //   children: [
        //     { element: <Navigate to="/dashboard/packages/packages-list" replace />, index: true },
        //     { path: 'packages-list', element: <PackagesList /> },
        //     { path: 'create-edit-packages', element: <CreateEditpackages /> },
        //   ],
        // },
        {
          path: 'event-tags',
          children: [
            {
              element: <EventTagsProvider />,
              children: [
                {
                  element: <EventTagsList />,
                  index: true,
                },
                { path: 'create', element: <CreateEditEventTags /> },
                { path: 'edit/:_id', element: <CreateEditEventTags edit /> },
              ],
            },
          ],
        },
        {
          path: 'events',
          children: [
            {
              element: <EventsTemplateProvider />,
              children: [
                {
                  element: <Events />,
                  index: true,
                },
                {
                  path: 'event-record/:id',
                  element: <EventRecords />,
                },
                {
                  path: "/dashboard/events/:type",
                  element: <CreateEvents />,
                },
                {
                  path: "/dashboard/events/edit-event/:handle/:type",
                  element: <CreateEvents isEdit />,
                },
              ],
            },
          ],
        },
        {
          path: 'discount',
          children: [
            {
              element: <DiscountTemplateProvider />,
              children: [
                {
                  element: <DiscountDashboard />,
                  index: true,
                },
                {
                  path: 'create_discount',
                  element: <CreateDiscount />,
                },
                {
                  path: '/dashboard/discount/:id',
                  element: <DiscountDetails />,
                },
                {
                  path: '/dashboard/discount/update/:id',
                  element: <UpdateDiscount />,
                },
              ],
            },
          ],
        },

        {
          path: 'Venues',
          children: [
            {
              element: <VenueTemplateProvider />,
              children: [
                {
                  element: <VenuesDashboard />,
                  index: true,
                },
                {
                  path: 'create_venue',
                  element: <CreateVenue />,
                },
                {
                  path: '/dashboard/Venues/:id',
                  element: <VenuePerDetails />,
                },
                {
                  path: '/dashboard/Venues/update/:id',
                  element: <UpdateVenue />,
                },
              ],
            },
          ],
        },

        {
          path: 'EventLabels',
          children: [
            {
              element: <VenueTemplateProvider />,
              children: [
                {
                  element: <EventLabelDashboard />,
                  index: true,
                },
                {
                  path: 'create_eventLabel',
                  element: <CreateEventLabel />,
                },

                {
                  path: '/dashboard/EventLabels/create_Strike_out_price',
                  element: <EventCreateStrikeOutPrice />,
                },
                {
                  path: '/dashboard/EventLabels/:id',
                  element: <UpdateEventLabel />,
                },
                // {
                //   path: '/dashboard/Venues/update/:id',
                //   element: <UpdateVenue />,
                // },
              ],
            },
          ],
        },

        {
          path: 'EventStrikes',
          children: [
            {
              element: <VenueTemplateProvider />,
              children: [
                {
                  element: <EventStrikeDashboard />,
                  index: true,
                },


                {
                  path: '/dashboard/EventStrikes/create_Strike_out_price',
                  element: <EventCreateStrikeOutPrice />,
                },

                {
                  path: '/dashboard/EventStrikes/:id',
                  element: <EventUpdateStrikeOutPrice />,
                },
                // {
                //   path: '/dashboard/Venues/update/:id',
                //   element: <UpdateVenue />,
                // },
              ],
            },
          ],
        },

        {
          path: 'artist',
          children: [
            {
              path: "/dashboard/artist",
              element: <ArtistDashboard />
            },
            {
              path: "/dashboard/artist/details/:artistId/:mobile",
              element: <ArtistDetails />
            }
          ]


        },

        {
          path: 'ticket-orders',
          element: <VendorProvider />,
          children: [
            {
              element: <TicketOrders />,
              index: true,
            },
          ],
        },
        {
          path: 'sponsored-events',
          element: <VendorProvider />,
          children: [
            {
              element: <SponsoredEvents />,
              index: true,
            },
            { path: 'create', element: <SponsoredEventsCreate /> },
          ],
        },
        {
          path: 'group-events',
          children: [
            {
              element: <GroupEvents />,
              index: true,
            },
            { path: 'create', element: <CreateGroupEvents /> },
            { path: 'edit/:name', element: <CreateGroupEvents edit /> },
          ],
        },

        {
          path: 'cancel-tickets',
          element: <VendorProvider />,
          children: [
            {
              element: <CancelTickets />,
              index: true,
            },
            { path: 'create', element: <AddCancelTickets /> },
          ],
        },
        {
          path: 'customer',
          children: [
            {
              element: <Customer />,
              index: true,
            },
          ],
        },
        {
          path: 'event-slot',
          element: <VendorProvider />,
          children: [
            {
              element: <EventSlotPage />,
              index: true,
            },
            {
              element: <CreateEventSlotPage />,
              path: 'create',
            },
          ],
        },

        {
          path: 'campaign',
          element: <VendorProvider />,
          children: [
            {
              path: 'package',
              element: <CampaignPackageProvider />,
              children: [
                {
                  element: <CreditsPackageList />,
                  index: true,
                },

                {
                  element: <CreateEditCreditPackage />,
                  path: 'create',
                },
                { path: 'edit/:name', element: <CreateEditCreditPackage edit /> },
              ],
            },
            {
              path: 'template',
              element: <CampaignTemplateProvider />,
              children: [
                {
                  element: <AddTemplateList />,
                  index: true,
                },

                {
                  element: <AddCampaignTemplate />,
                  path: 'add',
                },
                { path: 'edit/:name', element: <CreateEditCreditPackage edit /> },
              ],
            },
            {
              path: 'credits',
              element: <CampaignCreditsProvider />,
              children: [
                {
                  element: <AddCreditsList />,
                  index: true,
                },

                {
                  element: <AddCampaignCredits />,
                  path: 'add',
                },
                { path: 'edit/:name', element: <AddCampaignCredits edit /> },
              ],
            },

            // { path: 'create', element: <CreateEvents /> },
          ],
        },
        {
          path: 'maintenance',
          children: [
            {
              element: <Maintenance />,
              index: true,
            },
          ],
        },
      ],
    },
    {
      element: <CompactLayout />,
      children: [{ path: '404', element: <Page404 /> }],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
