import { createContext, useEffect, useContext, useCallback, useReducer, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useAuthContext } from '../auth/useAuthContext';

// utils
import axios from '../utils/axios';
import localStorageAvailable from '../utils/localStorageAvailable';

// ----------------------------------------------------------------------

const initialState = {
  businessTags: [],
  error: false,
  page: 0,
  totalPages: 1,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      ...state,
      businessTags: action.payload.businessTags,
      page: action.payload.page,
      totalPages: action.payload.totalPages,
    };
  }
  if (action.type === 'ERROR') {
    return {
      ...state,
      error: true,
    };
  }
  if (action.type === 'CREATE_TAG') {
    return {
      ...state,
      businessTags: [...state.businessTags, action.payload.businessTag],
    };
  }
  if (action.type === 'UPDATE_TAG') {
    const tags = [...state.businessTags];
    const index = tags.findIndex((tag) => tag._id === action.payload.businessTag._id);
    tags[index] = action.payload.businessTag;
    return {
      ...state,
      businessTags: tags,
    };
  }
  if (action.type === 'PAGE_CHANGE') {
    return {
      ...state,
      page: action.payload.page,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export const BusinessTagsContext = createContext(initialState);

export const useBusinessTagsContext = () => {
  const context = useContext(BusinessTagsContext);

  if (!context) throw new Error('useSettingsContext must be use inside SettingsProvider');

  return context;
};

export function BusinessTagsProvider() {
  const { user, logout } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();

  const storageAvailable = localStorageAvailable();

  const [state, dispatch] = useReducer(reducer, initialState);

  const getBusinessTags = useCallback(
    async (page = 0, page_size) => {
      try {
        const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: '/system/tags',
          headers: {
            authorization: `Bearer ${accessToken}`,
            user,
            'Content-Type': 'application/json',
          },
          params: { page, page_size },
        };
        const response = await axios.request(config);
        const { payload, currentPage, totalPages } = response.data;
        dispatch({
          type: 'INITIAL',
          payload: {
            businessTags: payload,
            page: currentPage,
            totalPages,
          },
        });
      } catch (error) {
        if (error === 'Unauthorized request') {
          logout();
        }
        console.log(error);
        dispatch({
          type: 'ERROR',
        });
      }
    }, [user, storageAvailable, logout]);

  useEffect(() => {
    getBusinessTags();
  }, [getBusinessTags]);

  const pageChange = useCallback(
    (page) => {
      getBusinessTags(page);
    },
    [getBusinessTags]
  );

  const createTag = useCallback(
    async (data) => {
      const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';
      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: '/system/tags',
        headers: {
          authorization: `Bearer ${accessToken}`,
          user,
          'Content-Type': 'application/json',
        },
        data,
      };
      const response = await axios.request(config);
      dispatch({
        type: 'CREATE_TAG',
        payload: {
          businessTag: response.data,
        },
      });
    },
    [storageAvailable, user]
  );

  const updateTag = useCallback(
    async (id, data) => {
      const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';
      const config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `/system/tags/${id}`,
        headers: {
          authorization: `Bearer ${accessToken}`,
          user,
          'Content-Type': 'application/json',
        },
        data,
      };
      const response = await axios.request(config);
      dispatch({
        type: 'UPDATE_TAG',
        payload: {
          businessTag: response.data,
        },
      });
    },
    [storageAvailable, user]
  );

  const memoizedValue = useMemo(
    () => ({ businessTags: state.businessTags, page: state.page, totalPages: state.totalPages, pageChange, createTag, updateTag }),
    [createTag, state.businessTags, updateTag, state.page, pageChange,
      state.totalPages,]
  );

  return (
    <BusinessTagsContext.Provider value={memoizedValue}>
      <Outlet />
    </BusinessTagsContext.Provider>
  );
}
