import { createContext, useContext } from 'react';
import { Outlet } from 'react-router-dom';

export const VenueTemplateContext = createContext();
export const VenueDetailsContext = () => {
    const context = useContext();
    if (!context) throw new Error('useSettingsContext must be use inside SettingsProvider');
    return context;
};

export function VenueTemplateProvider() {
    const memoizedValue = ""

    return (
        <VenueTemplateContext.Provider value={memoizedValue}>
            <Outlet />
        </VenueTemplateContext.Provider>
    );
}
